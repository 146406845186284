import {ReactElement, useState} from "react";
import {DrupalNode} from "next-drupal";
import {NodeDefaultPromoted, NodeDefaultTeaser} from "@/components/nodeTypes";

const LatestNodes = ({nodes}): ReactElement => {

  // Variables
  const [contentsRaw, setContentsRaw] = useState<DrupalNode[]>([])
  const contents = nodes.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf()).slice(0, 4)

  // Composant de rendu du premier élément
  const renderFirstContent = () => {
    let localContents = contents.slice(0, 1)
    return (
      localContents.length > 0 && localContents.map((content, i) => (
        <NodeDefaultPromoted key={'latestPromoted' + content.id} node={content} showType/>
      ))
    )
  }

  // Composant de rendu des éléments suivants
  const renderContents = () => {
    let localContents = contents.slice(1, 4)
    return (
      localContents.length > 0 && localContents.map((content, i) => (
        <NodeDefaultTeaser key={'latestNode' + content.id} node={content} className="basis-1/3" showType/>
      ))
    )
  }

  // Vue du composant à renvoyer
  return (
    <>
      <div>
        <div className="mb-6 -mx-4 md:mx-0">
          {renderFirstContent()}
        </div>
        <div className="flex flex-col md:flex-row gap-8">
          {renderContents()}
        </div>
      </div>
    </>
  )
}

export default LatestNodes
